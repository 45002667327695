body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin:0;
  padding: 0;
  box-sizing: border-box;
}

.App{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 2px solid blue;
  /* height: calc(100vh - 2px); */
}

header {
  background-color: blue;
  width: 100%;
  color: #f5f5f5;
  
  
}

main  {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-size: 1em;
  font-weight: 600;
  
  
}

footer {
  background-color: blue;
  width: 100%;
  color: #f5f5f5;
  margin-bottom: auto 0;
  font-weight: 500;
}

ul {
  width: 100%;
  list-style: none;
  padding: 0 0.25em 0.2em;
}

 ul li::before {
  content: '\2008';
} 

.item {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  margin: 0.35em 0;
  padding: 0.5em 0.5em;
  background: #f5f5f5;
}

/* .item:first-child {
  margin: 0;
} */

.item input[type="checkbox"] {
  text-align: center;
  width: 2rem;
  height:2rem;
  cursor: pointer;
  margin: 0 0.6rem 0 0;
}

.item input[type="checkbox"]:focus + label {
  text-decoration:"";
}

.item > label {
  font-size: 1.5em;
  flex: 1;
}

.item svg {
  width: 20px;
  height: 30px;
  cursor: pointer;
  color: steelblue;
}

.item svg:focus,
.item svg:hover {
  color: #f55;
  outline: none;
}



